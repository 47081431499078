import '@reach/dialog/styles.css';
import 'react-quill/dist/quill.bubble.css';

import { Provider } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import App, { Container } from 'next/app';
import Head from 'next/head';
import React from 'react';

import sentry from '../utils/sentry';
import theme, { fonts } from '../components/styles/theme';
import withReduxStore from '../lib/with-redux-store';

const { captureException } = sentry();
const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${fonts.sans};
  }

  div.quill.ql-lifetales .ql-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 1.5;
    font-size: 16px;
  }

  div.quill.ql-lifetales p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .quill.ql-lifetales h1,
  .quill.ql-lifetales h2,
  .quill.ql-lifetales h3,
  .quill.ql-lifetales h4,
  .quill.ql-lifetales h5,
  .quill.ql-lifetales h6 {
    font-family: inherit;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .quill.ql-lifetales h1 { font-size: 2.5rem !important; }
  .quill.ql-lifetales h2 { font-size: 2rem !important; }
  .quill.ql-lifetales h3 { font-size: 1.75rem !important; }
  .quill.ql-lifetales h4 { font-size: 1.5rem !important; }
  .quill.ql-lifetales h5 { font-size: 1.2rem !important; }
  .quill.ql-lifetales h6 { font-size: 1rem !important; }

  div.quill.ql-lifetales ol,
  div.quill.ql-lifetales ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  div.quill.ql-lifetales li { padding-bottom: .4em; }
  div.quill.ql-lifetales li:last-child { padding-bottom: 0; }

  .quill.ql-lifetales ol ol,
  .quill.ql-lifetales ul ul,
  .quill.ql-lifetales ol ul,
  .quill.ql-lifetales ul ol {
    margin-bottom: 0;
  }

  .quill.ql-lifetales a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  .quill.ql-lifetales a:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  .quill.ql-lifetales a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  .quill.ql-lifetales a:not([href]):hover {
    color: inherit;
    text-decoration: none;
  }

  .ql-indent-1 {
    padding-left: 3em;
  }

`;

class MyApp extends App {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return { pageProps };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <Container>
        <Provider store={reduxStore}>
          <ThemeProvider theme={theme}>
            <>
              <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

                <link
                  href="https://fonts.googleapis.com/css?family=Quicksand:400,700"
                  rel="stylesheet"
                  key="link-google-font-quicksand"
                />
              </Head>
              <Component {...pageProps} />
              <GlobalStyles />
            </>
          </ThemeProvider>
        </Provider>
      </Container>
    );
  }
}

export default withReduxStore(MyApp);
